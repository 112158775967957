<template>
  <div id="genre">
    <app-menu></app-menu>
    <admin-list-component
        :title="'Admin.menu.genre' | staticTranslation"
        :add-text="'Admin.components.addGenre' | staticTranslation"
        edit-path="/admin/program/genre/edit/"
        entity-name="genre"
        :get-name-function="getName"
        draggable="true"
    />
  </div>
</template>

<script>
import AdminMenu from "@/components/admin/AdminMenu";
import AdminListComponent from "@/components/admin/AdminListComponent";

export default {
  name: "ProgramGenre",
  components: {
    'app-menu': AdminMenu,
    'admin-list-component': AdminListComponent
  },
  methods: {
    getName: function (genre) {
      return genre.name;
    }
  }
}
</script>

<style scoped>
#genre {
  background-color: white;
  min-height: 100vh;
  overflow: hidden;
}
</style>
